import React, { useState } from 'react';

import ApexChart from 'react-apexcharts';

import { Container } from './styles';

export const Heatmap: React.FC = () => {
  const [series, setSeries] = useState([
    {
      name: 'Metric1',
      data: [10],
    },
    {
      name: 'Metric2',
      data: 10,
    },
    {
      name: 'Metric3',
      data: 10,
    },
    {
      name: 'Metric4',
      data: 10,
    },
    {
      name: 'Metric5',
      data: 10,
    },
    {
      name: 'Metric6',
      data: 10,
    },
    {
      name: 'Metric7',
      data: 10,
    },
    {
      name: 'Metric8',
      data: 10,
    },
    {
      name: 'Metric9',
      data: 10,
    },
  ]);

  const [options, setOptions] = useState({
    dataLabels: {
      enabled: false,
    },
    colors: ['#008FFB'],
    title: {
      text: 'HeatMap Chart (Single color)',
    },
  });
  return (
    <Container>
      <ApexChart
        options={options}
        series={series}
        type="heatmap"
        height={350}
      />
    </Container>
  );
};
