import React, { useContext } from 'react';

import UseAnimations from 'react-useanimations';
import menu2 from 'react-useanimations/lib/menu2';
import { ThemeContext } from 'styled-components';

import { LogoSmall1, UnifyLogoWhite } from '~/assets/icons';
import { useSidebar } from '~/hooks/sidebar';

import { Container, Session, Line, ButtonClose } from './styles';

import NavButton from './NavButton';
import SideBarData from './SideBarData';

const SideBar: React.FC = () => {
  const theme = useContext(ThemeContext);
  const { isSidebarOpened, setIsSidebarOpened } = useSidebar();
  return (
    <Container isOpened={isSidebarOpened}>
      <ButtonClose
        isOpened={isSidebarOpened}
        type="button"
        onClick={() => {
          setIsSidebarOpened(!isSidebarOpened);
        }}
      >
        <UseAnimations
          reverse={isSidebarOpened}
          strokeColor={theme.colors.white}
          size={40}
          animation={menu2}
          speed={3}
        />
      </ButtonClose>
      {isSidebarOpened && (
        <img
          src={UnifyLogoWhite}
          alt="Unify Logo Branca"
          style={{ height: 50, width: 'auto', margin: '0 auto' }}
        />
      )}
      {!isSidebarOpened && (
        <img
          src={LogoSmall1}
          alt="Unify Logo Pequena"
          style={{ height: 50, width: 'auto', margin: '0 auto' }}
        />
      )}
      {SideBarData.map((session) => (
        <Session>
          <Line />
          {session.map((sessionData) => (
            <NavButton sessionData={sessionData} />
          ))}
        </Session>
      ))}
    </Container>
  );
};

export default SideBar;
