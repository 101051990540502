import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Loading } from '~/components';
import { useLoading } from '~/hooks/loading';
import * as Guest from '~/pages/Guest';

import { AdminRoutes } from './admin.routes';
import AdminRoute from './types/admin.route';

const Routes: React.FC = () => {
  const { isLoading, percent } = useLoading();
  return (
    <BrowserRouter>
      <Loading loading={isLoading} percent={percent} />
      <Switch>
        <Route path="/" exact component={Guest.SignIn} />
        <AdminRoute isPrivate component={AdminRoutes} />
        {/* <Route path="*" exact component={Guest.NotFoundPage} /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
