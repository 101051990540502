import styled from 'styled-components';

import { LoginBackgroundFasano } from '~/assets/images';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: url(${LoginBackgroundFasano}) 50% / cover;
  background-color: ${({ theme }) => theme.colors.blue};
`;

export const Content = styled.div`
  width: 100%;
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
`;

export const Card = styled.div`
  width: 70%;
  /* min-width: 53.2rem;
  max-width: 53.2rem; */
  min-height: 34rem;
  background-color: #eae9e9;
  border-radius: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0 4rem;

  form {
    width: 100%;
    max-width: 34rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 590px) {
    min-width: 100%;
  }
`;
