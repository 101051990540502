import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import Button from '~/components/Buttons/Button';
import { useAuth } from '~/hooks/auth';

import { Container } from './styles';

type Props = {
  userName: string;
};

const DropdownMenu: React.FC<Props> = ({ userName }) => {
  const { logout } = useAuth();
  const theme = useContext(ThemeContext);
  const handleLogout = (): void => {
    logout();
  };
  return (
    <Container>
      <div
        style={{
          width: 100,
          height: 100,
          borderRadius: '50%',
          backgroundColor: theme.colors.primary_color,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1>{userName[0].toUpperCase()}</h1>
      </div>

      <Button
        style={{
          width: 160,
          border: `2px solid ${theme.colors.red}`,
          boxShadow: 'none',
        }}
        bgColor="transparent"
        textColor={theme.colors.red}
        onClick={handleLogout}
      >
        Sair
      </Button>
    </Container>
  );
};

export default DropdownMenu;
