import styled from 'styled-components';

type ImageContainerProps = {
  color: string;
};

export const Container = styled.div`
  width: 38rem;
  height: 8rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  padding: 0 1.6rem;

  > h1 {
    font-size: 28px;
    font-family: 'Segoe UI';
    font-weight: 600px;
    margin-left: 2rem;
  }
`;

export const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background-color: ${(props) => props.color};

  img {
    width: 3.6rem;
    height: auto;
  }
`;
