import { ClipLoader as ClipLoaderBase } from 'react-spinners';
import styled from 'styled-components';

export const ClipLoader = styled(ClipLoaderBase)`
  margin: 0 auto;
  position: fixed;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
`;
