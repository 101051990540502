import React, { ReactElement } from 'react';

import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '~/hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  // eslint-disable-next-line react/require-default-props
  isPrivate?: boolean;
  component: React.ComponentType;
}

function AdminRoute({
  isPrivate = false,
  component: Component,
  ...rest
}: RouteProps): ReactElement {
  const { user } = useAuth();
  const role = user ? user.role : null;

  return (
    <ReactDOMRoute
      {...rest}
      render={() =>
        !isPrivate || role === 'admin' ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  );
}

export default AdminRoute;
