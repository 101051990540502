import React, { ReactNode } from 'react';

import { Page, Container } from './styles';

import { NavBar } from '../..';

type Props = {
  children?: ReactNode;
};

const PageContainerWithoutSidebar: React.FC = ({ children }: Props) => {
  return (
    <Container>
      <NavBar />
      <div>
        <Page>{children}</Page>
      </div>
    </Container>
  );
};

export default PageContainerWithoutSidebar;
