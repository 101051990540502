import { AxiosPromise } from 'axios';

import { ICredentials } from '~/models/Auth';
import { IUser } from '~/models/User';

import api from './api';

const SessionService = {
  signin(data: ICredentials): AxiosPromise<{ user: IUser; token: string }> {
    return api.post('/auth/login', data);
  },
};

export default SessionService;
