import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  /* flex-wrap: wrap; */
`;
