import { AxiosPromise } from 'axios';

import { Circuit } from '~/models/Circuits';

import api from './api';

const CircuitsService = {
  getCircuits(): AxiosPromise<Circuit[]> {
    return api.get('/circuits');
  },
  commute(circuit_id: string): AxiosPromise<Circuit[]> {
    return api.post(`/circuit_automation/commute/${circuit_id}`);
  },
  setTimeProfile(
    circuit_id: string,
    timeOn: string,
    timeOff: string,
  ): AxiosPromise<Circuit[]> {
    return api.post(`/circuit_automation/set_time_profile/${circuit_id}`, {
      timeOn,
      timeOff,
    });
  },
};

export default CircuitsService;
