import { css } from 'styled-components';

import * as LocalFonts from '~/assets/fonts';

export const fontFaces = css`
  // Local fonts
  @font-face {
    font-family: 'Segoe UI';
    src: url(${LocalFonts.SegoeUI}) format('truetype');
  }
`;
