import styled from 'styled-components';

export const Container = styled.div`
  width: 240px;
  height: 260px;
  border-radius: 10px;
  background-color: white;
  position: fixed;
  right: 16px;
  margin-top: 5px;
  z-index: 1000;
  box-shadow: 0.1rem 1rem 1rem #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #ffffff;

  h1 {
    font-size: 70px !important;
  }
`;
