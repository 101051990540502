/* eslint-disable react/require-default-props */
import React from 'react';

import { Container, ImageContainer } from './styles';

type Props = {
  title: string;
  icon: string;
  color?: string;
};

export const TitleCard: React.FC<Props> = ({ title, icon, color = '#fff' }) => {
  return (
    <Container>
      <ImageContainer color={color}>
        <img src={icon} alt={`Ícone ${title}`} />
      </ImageContainer>
      <h1>{title}</h1>
    </Container>
  );
};
