import React, { Suspense } from 'react';

// import '~/locales';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GlobalStyles from './styles/global';

import { AppProvider } from './hooks';
import Routes from './routes';

const App = (): JSX.Element => {
  return (
    <>
      <AppProvider>
        <Suspense fallback={<p>Loading...</p>}>
          <ToastContainer />
          <GlobalStyles />
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Suspense>
      </AppProvider>
    </>
  );
};

export default App;
