import React, { useContext, useEffect, useRef, useState } from 'react';

import { BsArrowReturnRight } from 'react-icons/bs';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import { useSidebar } from '~/hooks/sidebar';

import { Nav, SubNav, SubNavContainer, Wrapper } from './styles';

import { Data } from '../SideBarData';

type Props = {
  sessionData: Data;
};

const NavButton: React.FC<Props> = ({ sessionData }) => {
  const theme = useContext(ThemeContext);
  const { push } = useHistory();
  const { isSidebarOpened } = useSidebar();
  const [showSubnav, setShowSubnav] = useState(false);

  const ref: any = useRef(null);

  const handleClickOutside = (event: any): void => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowSubnav(false);
    }
  };

  useEffect((): any => {
    if (sessionData.subNav && !isSidebarOpened) {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
    return null;
  }, [isSidebarOpened, sessionData.subNav]);

  return (
    <Wrapper ref={ref}>
      <Nav
        isOpened={isSidebarOpened}
        onClick={() => {
          sessionData.subNav
            ? setShowSubnav((prevState) => !prevState)
            : push(sessionData.path);
        }}
      >
        <div>
          <img src={sessionData.icon} alt={`${sessionData.title} Icon`} />
        </div>
        {isSidebarOpened && <p>{sessionData.title}</p>}
        {sessionData.subNav &&
          (showSubnav ? (
            <RiArrowUpSFill
              color={theme.colors.white}
              style={{ marginLeft: 5 }}
            />
          ) : (
            <RiArrowDownSFill
              color={theme.colors.white}
              style={{ marginLeft: 5 }}
            />
          ))}
      </Nav>
      {sessionData.subNav && showSubnav && (
        <SubNavContainer isOpened={isSidebarOpened}>
          {sessionData.subNav.map((subNav) => (
            <SubNav
              isOpened={isSidebarOpened}
              onClick={() => {
                push(`${sessionData.path}${subNav.path}`);
              }}
            >
              <BsArrowReturnRight color={theme.colors.white} />
              <p>{subNav.title}</p>
            </SubNav>
          ))}
        </SubNavContainer>
      )}
    </Wrapper>
  );
};

export default NavButton;
