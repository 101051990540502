import React, { ReactNode } from 'react';

import { Container } from './styles';

type Props = {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
};

const BaseContainer: React.FC = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

export default BaseContainer;
