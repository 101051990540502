import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';

interface SidebarContextData {
  readonly isSidebarOpened: boolean;
  setIsSidebarOpened: (state: boolean) => void;
}

const SidebarContext = createContext<SidebarContextData>(
  {} as SidebarContextData,
);

interface SidebarProviderProps {
  children: ReactNode;
}

function SidebarProvider({ children }: SidebarProviderProps): ReactElement {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  return (
    <SidebarContext.Provider value={{ isSidebarOpened, setIsSidebarOpened }}>
      {children}
    </SidebarContext.Provider>
  );
}

function useSidebar(): SidebarContextData {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error('useSidebar problems');
  }

  return context;
}

export { useSidebar, SidebarProvider };
