import React, { useContext } from 'react';

import Switch from 'react-switch';
import { ThemeContext } from 'styled-components';

import { Container } from './styles';

type Props = {
  isChecked: boolean;
  onChange: () => void;
};

const SwitchInput: React.FC<Props> = ({ isChecked, onChange }) => {
  const theme = useContext(ThemeContext);
  const onText = (
    <h1 style={{ color: '#FFF', fontSize: 12, padding: 5 }}>ON</h1>
  );
  const offText = (
    <h1 style={{ color: '#FFF', fontSize: 12, padding: 5 }}>OFF</h1>
  );
  return (
    <Container>
      <Switch
        onChange={onChange}
        checked={isChecked}
        checkedIcon={onText}
        onColor={theme.colors.blue_light}
        uncheckedIcon={offText}
      />
    </Container>
  );
};

export default SwitchInput;
