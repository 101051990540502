import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { LargeCard, Heatmap, ValueCard } from '~/components';
import { PageWithSidebar } from '~/templates/PageWithSidebar';

import * as S from './styles';

export const EnergyConsumption: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <PageWithSidebar>
      <S.Container>
        <S.Row>
          <ValueCard
            title="DEMANDA ATIVA"
            subtitle="Horário de ponta"
            value="35 kW"
            color={theme.colors.green_pastel}
          />
          <ValueCard
            title="DEMANDA ATIVA"
            subtitle="Horário fora de ponta"
            value="60 kW"
            color={theme.colors.green_pastel}
          />
          <ValueCard
            title="CONSUMO ATIVO"
            subtitle="Horário de ponta"
            value="80 kW"
            color={theme.colors.green_pastel}
          />
          <ValueCard
            title="CONSUMO ATIVO"
            subtitle="Horário intermediário"
            value="320 kW"
            color={theme.colors.green_pastel}
          />
          <ValueCard
            title="CONSUMO ATIVO"
            subtitle="Horário fora de ponta"
            value="320 kW"
            color={theme.colors.green_pastel}
          />
        </S.Row>

        <LargeCard>
          <h1>PERFIL DE CONSUMO ATIVO</h1>
          <Heatmap />
        </LargeCard>

        <S.Row>
          <LargeCard>
            <h1>CONSUMO ATIVO ATUAL</h1>
          </LargeCard>

          <LargeCard>
            <h1>LOGS DE CONSUMO ATIVO</h1>
          </LargeCard>
        </S.Row>

        <S.Row>
          <ValueCard
            title="DEMANDA REATIVA"
            subtitle="Horário de ponta"
            value="35 kW"
            color={theme.colors.green_pastel}
          />
          <ValueCard
            title="DEMANDA REATIVA"
            subtitle="Horário fora de ponta"
            value="60 kW"
            color={theme.colors.green_pastel}
          />
          <ValueCard
            title="CONSUMO REATIVO"
            subtitle="Horário de ponta"
            value="80 kW"
            color={theme.colors.green_pastel}
          />
          <ValueCard
            title="CONSUMO REATIVO"
            subtitle="Horário intermediário"
            value="320 kW"
            color={theme.colors.green_pastel}
          />
          <ValueCard
            title="CONSUMO REATIVO"
            subtitle="Horário fora de ponta"
            value="320 kW"
            color={theme.colors.green_pastel}
          />
        </S.Row>

        <LargeCard>
          <h1>PERFIL DE CONSUMO REATIVO</h1>
          <Heatmap />
        </LargeCard>

        <S.Row>
          <LargeCard>
            <h1>CONSUMO REATIVO ATUAL</h1>
          </LargeCard>

          <LargeCard>
            <h1>LOGS DE CONSUMO REATIVO</h1>
          </LargeCard>
        </S.Row>
      </S.Container>
    </PageWithSidebar>
  );
};
