import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;

export const CardRow = styled.div`
  width: 100%;
  display: flex;
  gap: 5rem;
  flex-wrap: wrap;
  margin-top: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    form {
      width: 190px !important;
    }
    button {
      width: 190px !important;
      margin-top: -30px;
      margin-left: 3px !important;
    }
  }
`;
