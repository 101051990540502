import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-height: 40rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  padding: 2rem;

  > h1 {
    font-size: 22px;
    font-family: 'Segoe UI';
    font-weight: 600px;
    margin-left: 2rem;
  }
`;
