import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 7rem;
  background: ${({ theme }) => theme.colors.primary_color};
  box-shadow: inset 0px -7px 7px #00000029;

  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${({ theme }) => theme.colors.white};
  }

  button {
    background-color: transparent;
    height: 7rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    &:hover {
      background-color: #00000025;
    }

    p {
      color: #fff;
      font-size: 22px;
      margin-right: 5px;
    }
  }
  @media (max-width: 650px) {
    img {
      max-width: 100px;
    }
  }

  @media (max-width: 450px) {
    .hideOnMobile {
      display: none;
    }
    button {
      margin-left: auto;
    }
  }
`;
