import React, { useState } from 'react';

import ApexChart from 'react-apexcharts';

import { Container } from './styles';

type ChartProps = {
  yaxis: { name: string; data: number[] }[];
  xaxis: string[];
  colors?: string[];
};

export const ColumnChart: React.FC<ChartProps> = ({
  yaxis,
  xaxis,
  colors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63'],
}) => {
  const [series] = useState<any[]>(yaxis);

  const [options] = useState<ApexCharts.ApexOptions>({
    chart: {
      type: 'bar',
      height: 350,
    },
    colors,
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: xaxis,
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `R$ ${val}`;
        },
      },
    },
  });

  return (
    <Container>
      <ApexChart options={options} series={series} type="bar" height={350} />
    </Container>
  );
};
