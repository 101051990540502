import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  ReactElement,
} from 'react';

import { AxiosPromise } from 'axios';
import * as dateFns from 'date-fns';

import { ICredentials } from '~/models/Auth';
import { IUser } from '~/models/User';
import api from '~/services/api';
import SessionService from '~/services/Session';

interface AuthContextData {
  user: IUser;
  signin({ email, password }: ICredentials): Promise<void>;
  logout(): void;
}

interface AuthProps {
  children: ReactNode;
}

interface AuthState {
  token: string;
  user: IUser;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }: AuthProps): ReactElement {
  const PREFIX = '@Unify';

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(`${PREFIX}:token`);
    const userString = localStorage.getItem(`${PREFIX}:user`);

    const expires_at = localStorage.getItem(`${PREFIX}:token.expires_at`);

    if (expires_at) {
      const convertedDate: Date = new Date(expires_at);
      const validateDate = dateFns.addDays(convertedDate, 2);

      convertedDate >= validateDate && logout();
    }

    if (token && userString) {
      const user: IUser = JSON.parse(userString);
      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      return { user, token };
    }

    return {} as AuthState;
  });

  const signin = async ({ email, password }: ICredentials): Promise<void> => {
    const user = data;
    if (user) logout();

    try {
      const response = await SessionService.signin({
        email,
        password,
      });

      localStorage.setItem(`${PREFIX}:token`, response.data.token);

      localStorage.setItem(
        `${PREFIX}:user`,
        JSON.stringify(response.data.user),
      );

      const json = {
        user: response.data.user,
        token: response.data.token,
      };

      api.defaults.headers.common.Authorization = `Bearer ${json.token}`;

      setData(json);
    } catch (error: any) {
      throw new Error('Insira credenciais válidas');
    }
  };

  const logout = (): void => {
    ['token', 'user'].forEach((key) => {
      localStorage.removeItem(`${PREFIX}:${key}`);
    });

    setData({} as AuthState);
    api.defaults.headers.common.Authorization = '';
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signin,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
