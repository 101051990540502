import React, { ReactNode } from 'react';

import { Container } from './styles';

type Props = {
  children?: ReactNode;
};

export const LargeCard: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};
