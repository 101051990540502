import React, { useState } from 'react';

import { IoChevronDownOutline } from 'react-icons/io5';

import { LogoFasano, UnifyLogoWhite } from '~/assets/icons';
import { useAuth } from '~/hooks/auth';

import { Container } from './styles';

import DropdownMenu from './DropdownMenu';

const NavBar: React.FC = () => {
  const { user } = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  return (
    <>
      <Container>
        <img
          src={LogoFasano}
          className="hideOnMobile"
          style={{ height: 30, width: 'auto' }}
          alt="Logo do Fasano"
        />
        <img
          src={UnifyLogoWhite}
          style={{ height: 50, width: 'auto' }}
          alt="Logo do Unify"
        />
        <button
          type="button"
          onClick={() => {
            setDropdownVisible((prevState) => !prevState);
          }}
        >
          <p>{user.name}</p>
          <IoChevronDownOutline size={22} />
        </button>
      </Container>
      {dropdownVisible && <DropdownMenu userName={user.name} />}
    </>
  );
};

export default NavBar;
