import React, { ReactElement } from 'react';

import { IconType } from 'react-icons';
import * as RiIcons from 'react-icons/ri';

import {
  CircuitWhite,
  DeviceWhite,
  EnergyPlugWhite,
  MoneyWhite,
  PumpWhite,
  UsersWhite,
  WaterWhite,
} from '~/assets/icons';

type SubData = {
  title: string;
  path: string;
};

export type Data = {
  title: string;
  path: string;
  icon: string;
  subNav?: SubData[];
};

const SideBarFirstSession: Data[] = [
  {
    title: 'Central de Faturas',
    path: '/billing',
    icon: MoneyWhite,
  },
  {
    title: 'Gestão de Energia',
    path: '/energy',
    icon: EnergyPlugWhite,

    subNav: [
      {
        title: 'Relatórios de Consumo',
        path: '/consumption',
      },
      {
        title: 'Relatórios de Setores',
        path: '/sectors',
      },
      {
        title: 'Relatórios de Geração',
        path: '/generation',
      },
      {
        title: 'Relatórios de Geradores',
        path: '/generators',
      },
    ],
  },
  {
    title: 'Gestão de Água',
    path: '/water',
    icon: WaterWhite,

    subNav: [
      {
        title: 'Relatórios de Consumo',
        path: '/consumption',
      },
      {
        title: 'Relatórios de Setores',
        path: '/sectors',
      },
      {
        title: 'Relatórios de Captação',
        path: '/captation',
      },
      {
        title: 'Relatórios das Bombas',
        path: '/pump',
      },
    ],
  },
  {
    title: 'Gestão de Recursos',
    path: '/resources',
    icon: PumpWhite,
  },
  {
    title: 'Automação de Circuitos',
    path: '/circuits',
    icon: CircuitWhite,
  },
];

const SideBarSecondSession: Data[] = [
  {
    title: 'Gerenciar Dispositivos',
    path: '/devices',
    icon: DeviceWhite,
  },
  {
    title: 'Gerenciar Usuários',
    path: '/users',
    icon: UsersWhite,
  },
];

export default [SideBarFirstSession, SideBarSecondSession];
