import React, { ReactNode } from 'react';

import { BaseContainer, PageContainerWithoutSidebar } from '~/components';

type Props = {
  children?: ReactNode;
};

export const PageWithoutSidebar: React.FC<Props> = ({ children }) => {
  return (
    <BaseContainer>
      <PageContainerWithoutSidebar>{children}</PageContainerWithoutSidebar>
    </BaseContainer>
  );
};
