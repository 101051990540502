import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { ClipLoader } from './styles';

interface LoadingProps {
  loading: boolean;
  percent?: number;
}

const Loading: React.FC<LoadingProps> = ({ loading, percent }) => {
  const theme = useContext(ThemeContext);
  return (
    <>
      {loading && (
        <div
          style={{
            position: 'fixed',
            width: 'calc(100vw - 15px)',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0,0,0, 0.7)',
            zIndex: 1001,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
            }}
          >
            <ClipLoader
              color={theme.colors.primary_color}
              loading={loading}
              size={120}
            />
            <div
              style={{
                color: theme.colors.primary_color,
                fontSize: 14,
                textAlign: 'center',
              }}
            >
              {percent && percent > 0 ? `${percent?.toFixed(0)}%` : ''}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Loading.defaultProps = {
  percent: 0,
};

export default Loading;
