import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { EnergyPlugWhite, WaterWhite } from '~/assets/icons';
import { TitleCard, ValueCard, LargeCard, ColumnChart } from '~/components';
import { PageWithSidebar } from '~/templates/PageWithSidebar';

import * as S from './styles';

export const Billing: React.FC = () => {
  const theme = useContext(ThemeContext);
  const BillingMock = [
    {
      name: 'Valor da Fatura',
      data: [1300, 1250, 1300, 1250, 1300, 1250, 1300, 1250],
    },
    {
      name: 'Total Economizado',
      data: [900, 1000, 900, 1000, 900, 1000, 900, 1000],
    },
    {
      name: 'Investimento em Faturas',
      data: [500, 700, 500, 700, 500, 700, 500, 700],
    },
    {
      name: 'Total Retornado',
      data: [200, 400, 200, 400, 200, 400, 200, 400],
    },
  ];
  return (
    <PageWithSidebar>
      <S.Container>
        <TitleCard
          title="FATURAS DE ENERGIA"
          icon={EnergyPlugWhite}
          color={theme.colors.green_pastel}
        />
        <S.Row>
          <ValueCard
            title="FATURA ACUMULADA"
            value="R$350,00"
            color={theme.colors.green_pastel}
          >
            <p>
              ECONOMIA ESTIMADA:{' '}
              <strong style={{ color: theme.colors.green_pastel }}>20%</strong>
            </p>
          </ValueCard>
          <ValueCard
            title="FATURA ANTERIOR"
            value="R$350,00"
            color={theme.colors.green_pastel}
          >
            <p>
              ECONOMIA ESTIMADA:{' '}
              <strong style={{ color: theme.colors.green_pastel }}>20%</strong>
            </p>
          </ValueCard>
          <ValueCard
            title="FATURAÇÃO SEMESTRAL"
            value="R$350,00"
            color={theme.colors.green_pastel}
          >
            <p>
              ECONOMIA ESTIMADA:{' '}
              <strong style={{ color: theme.colors.green_pastel }}>20%</strong>
            </p>
          </ValueCard>
          <ValueCard
            title="FATURAÇÃO ANUAL"
            value="R$350,00"
            color={theme.colors.green_pastel}
          >
            <p>
              ECONOMIA ESTIMADA:{' '}
              <strong style={{ color: theme.colors.green_pastel }}>20%</strong>
            </p>
          </ValueCard>
        </S.Row>
        <LargeCard>
          <h1>HISTÓRICO DE FATURAS</h1>
          <ColumnChart
            yaxis={BillingMock}
            xaxis={[
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ].splice(0, 8)}
            colors={['#64D7A6', '#86E0BA', '#A9E8CD', '#CBF1E1']}
          />
        </LargeCard>

        <TitleCard
          title="FATURAS DE ÁGUA"
          icon={WaterWhite}
          color={theme.colors.blue_pastel}
        />
        <S.Row>
          <ValueCard
            title="FATURA ACUMULADA"
            value="R$350,00"
            color={theme.colors.blue_pastel}
          >
            <p>
              ECONOMIA ESTIMADA:{' '}
              <strong style={{ color: theme.colors.blue_pastel }}>20%</strong>
            </p>
          </ValueCard>
          <ValueCard
            title="FATURA ANTERIOR"
            value="R$350,00"
            color={theme.colors.blue_pastel}
          >
            <p>
              ECONOMIA ESTIMADA:{' '}
              <strong style={{ color: theme.colors.blue_pastel }}>20%</strong>
            </p>
          </ValueCard>
          <ValueCard
            title="FATURAÇÃO SEMESTRAL"
            value="R$350,00"
            color={theme.colors.blue_pastel}
          >
            <p>
              ECONOMIA ESTIMADA:{' '}
              <strong style={{ color: theme.colors.blue_pastel }}>20%</strong>
            </p>
          </ValueCard>
          <ValueCard
            title="FATURAÇÃO ANUAL"
            value="R$350,00"
            color={theme.colors.blue_pastel}
          >
            <p>
              ECONOMIA ESTIMADA:{' '}
              <strong style={{ color: theme.colors.blue_pastel }}>20%</strong>
            </p>
          </ValueCard>
        </S.Row>
        <LargeCard>
          <h1>HISTÓRICO DE FATURAS</h1>
          <ColumnChart
            yaxis={BillingMock}
            xaxis={[
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ].splice(0, 8)}
            colors={['#35A5AC', '#62B9BF', '#8FCDD1', '#BCE1E3']}
          />
        </LargeCard>
      </S.Container>
    </PageWithSidebar>
  );
};
