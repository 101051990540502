import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  max-width: 29.867rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  padding: 2rem;

  > h1 {
    font-family: 'Segoe UI';
    font-weight: 500;
    font-size: 50px;
    text-align: center;
  }

  > h2 {
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 16px;
    align-self: flex-start;
  }

  > h3 {
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 14px;
    align-self: flex-start;
  }
`;

export const Line = styled.div`
  width: 90%;
  height: 0.5px;
  background-color: #00000060;
  align-self: center;
`;
