import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import ReactDom from 'react-dom';
import { IoMdClose } from 'react-icons/io';

import { Container, BackgroundOverlay, ButtonClose } from './styles';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const Modal: React.FC<Props> = ({ children, isOpen, setIsOpen }) => {
  if (!isOpen) return null;
  return ReactDom.createPortal(
    <>
      <BackgroundOverlay
      // onClick={() => {
      //   setIsOpen(false);
      // }}
      />
      <Container>
        <ButtonClose
          type="button"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <IoMdClose size={24} color="#00000070" />
        </ButtonClose>
        {children}
      </Container>
    </>,
    document.getElementById('portal')!,
  );
};
