import React, { useState, useContext, useEffect, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiEdit } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import * as Yup from 'yup';

import { LightWhite } from '~/assets/icons';
import { Button, IconCard, Input, SwitchInput } from '~/components';
import { Modal } from '~/components/Modal';
import { useLoading } from '~/hooks/loading';
import { Circuit } from '~/models/Circuits';
import CircuitsService from '~/services/Circuits';
import { PageWithoutSidebar } from '~/templates/PageWithoutSidebar';
import getValidationErrors from '~/validators/getValidationsErrors';

import * as S from './styles';

export const Circuits: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [circuits, setCircuits] = useState<Circuit[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCircuit, setSelectedCircuit] = useState<Circuit>();
  const [selectedCircuitIndex, setSelectedCircuitIndex] = useState(0);

  const theme = useContext(ThemeContext);
  const disabledFormRef = useRef<FormHandles>(null);
  const scheduleFormRef = useRef<FormHandles>(null);
  const { setLoading } = useLoading();

  const getCircuits = async (): Promise<void> => {
    const response = await CircuitsService.getCircuits();
    setCircuits(response.data);
  };

  useEffect(() => {
    getCircuits();
  }, []);

  const handleSubmitSchedule = async (data: {
    schedule_on: string;
    schedule_off: string;
  }): Promise<void> => {
    try {
      setLoading(true);

      if (!selectedCircuit)
        throw new Error('Não foi possível identificar o circuito selecionado');
      const schema = Yup.object().shape({
        schedule_on: Yup.string().required('Horário inicial obrigatório'),
        schedule_off: Yup.string().required('Horário final obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await CircuitsService.setTimeProfile(
        selectedCircuit.id,
        data.schedule_on,
        data.schedule_off,
      );

      console.log('response', response);

      const circuitsCopy = circuits;
      circuitsCopy[selectedCircuitIndex].timeOn = data.schedule_on;
      circuitsCopy[selectedCircuitIndex].timeOff = data.schedule_off;

      setCircuits(circuitsCopy);
      setModalOpen(false);
      setLoading(false);
      scheduleFormRef.current?.reset();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        Object.values(errors).forEach((value: string) => {
          toast.error(`${value}`);
        });

        scheduleFormRef.current?.setErrors(errors);
      } else {
        toast.error(`${error}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchClick = async (
    circuit: Circuit,
    index: number,
  ): Promise<void> => {
    try {
      setLoading(true);

      if (!circuit.id)
        throw new Error('Não foi possível identificar o circuito selecionado');

      await CircuitsService.commute(circuit.id);

      const circuitsCopy = circuits;
      circuitsCopy[index].status = !isChecked ? 'on' : 'off';
      setCircuits(circuitsCopy);

      setIsChecked((prevState) => !prevState);

      toast.success(
        `Circuito ${!isChecked ? 'ligado' : 'desligado'} com sucesso!`,
      );
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        Object.values(errors).forEach((value: string) => {
          toast.error(`${value}`);
        });

        scheduleFormRef.current?.setErrors(errors);
      } else {
        toast.error(`${error}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWithoutSidebar>
      <S.Container>
        <Modal isOpen={modalOpen} setIsOpen={setModalOpen}>
          <h2 style={{ marginTop: 20 }}>
            Automatizar {selectedCircuit?.name || 'Circuito não encontrado'}
          </h2>
          <Form ref={scheduleFormRef} onSubmit={handleSubmitSchedule}>
            <Input
              type="time"
              name="schedule_on"
              placeholder="Horário de Ligar"
              label="Horário de Ligar"
            />
            <Input
              type="time"
              name="schedule_off"
              placeholder="Horário de Desligar"
              label="Horário de Desligar"
            />
            <Button
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => {
                scheduleFormRef.current?.submitForm();
              }}
            >
              Salvar
            </Button>
          </Form>
        </Modal>

        <S.Row>
          {circuits.map((circuit, index) => (
            <IconCard
              title={circuit.name}
              subtitle={circuit.sectorName}
              icon={LightWhite}
              color={theme.colors.blue_light}
              key={`${circuit.name}-${circuit.controllerId}-${circuit.sectorName}`}
            >
              <S.CardRow>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3 style={{ marginBottom: 7 }}>Manual</h3>
                  <SwitchInput
                    isChecked={circuit.status === 'on'}
                    onChange={() => {
                      handleSwitchClick(circuit, index);
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3>Automático</h3>
                  <S.Row
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Form
                      style={{ width: 240 }}
                      ref={disabledFormRef}
                      onSubmit={() => null}
                    >
                      <Input
                        name="show-circuit-schedule"
                        value={
                          circuit.timeOn && circuit.timeOff
                            ? `ON: ${circuit.timeOn} OFF: ${circuit.timeOff}`
                            : 'Não cadastrado'
                        }
                        placeholder="Agendar Horário"
                        disabled
                      />
                    </Form>
                    <Button
                      style={{
                        width: 40,
                        minWidth: 40,
                        marginBottom: 20,
                        marginLeft: -10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.colors.blue_light,
                      }}
                      onClick={() => {
                        setModalOpen(true);
                        setSelectedCircuit(circuit);
                        setSelectedCircuitIndex(index);
                      }}
                    >
                      <FiEdit color="#fff" size={20} />
                    </Button>
                  </S.Row>
                </div>
              </S.CardRow>
            </IconCard>
          ))}
        </S.Row>
      </S.Container>
    </PageWithoutSidebar>
  );
};
