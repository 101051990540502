import React, { ReactNode } from 'react';

import { Container, Line } from './styles';

type Props = {
  title: string;
  subtitle?: string;
  value: string;
  color?: string;
  children?: ReactNode;
};

export const ValueCard: React.FC<Props> = ({
  title,
  subtitle,
  value,
  color = '#000',
  children,
}) => {
  return (
    <Container>
      <h2>{title}</h2>
      <h3>{subtitle}</h3>
      <h1 style={{ color }}>{value}</h1>
      {children && <Line />}
      <div style={{ marginTop: 10 }}>{children}</div>
    </Container>
  );
};
