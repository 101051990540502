import React, { useRef, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { UnifyLogoBlack } from '~/assets/icons';
import { Input, Button, InputCheckbox } from '~/components';
import { useAuth } from '~/hooks/auth';
import { useLoading } from '~/hooks/loading';
import { ICredentials } from '~/models/Auth';
import getValidationErrors from '~/validators/getValidationsErrors';

import * as S from './styles';

export const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { push } = useHistory();
  const { signin } = useAuth();
  const { setLoading } = useLoading();

  const handleSubmit = useCallback(
    async (data: ICredentials) => {
      try {
        setLoading(true);
        data.email = data.email.trim();
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Email inválido')
            .required('Email obrigatório'),
          password: Yup.string().min(3, 'Senha com no mínimo 3 caracteres'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signin(data);

        setLoading(false);
        push('/circuits');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          Object.values(errors).forEach((value: string) => {
            toast.error(`${value}`);
          });

          formRef.current?.setErrors(errors);
        } else {
          toast.error(`${error}`);
        }
      } finally {
        setLoading(false);
      }
    },
    [push, setLoading, signin],
  );

  return (
    <S.Container>
      <S.Content>
        <S.Card>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <img
              src={UnifyLogoBlack}
              alt="Logo Unify Preta"
              style={{ height: 60, width: 'auto', marginBottom: 20 }}
            />
            <Input name="email" placeholder="E-mail" />
            <Input name="password" placeholder="Senha" showIconPassword />
            <InputCheckbox
              name="keep_connected"
              options={[
                {
                  id: 'keep_connected',
                  label: 'Mantenha-me conectado',
                  value: true,
                },
              ]}
              containerStyle={{
                marginBottom: 20,
                fontSize: 14,
              }}
            />
            <Button onClick={() => formRef.current?.submitForm()}>
              Entrar
            </Button>
          </Form>
        </S.Card>
      </S.Content>
    </S.Container>
  );
};
