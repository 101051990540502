export default {
  colors: {
    primary_color: '#BBB099',
    white: '#FFFFFF',
    white_medium: '#EEEEEE',
    black: '##1C1C1C',
    gray_light: '#EFEFEF',
    gray_medium: '#A8A8A8',
    blue: '#4E7085',
    blue_light: '#6B9AB7',
    red: '#c53030',
    green_pastel: '#52D29C',
    blue_pastel: '#1E9BA3',
  },
  spacings: {
    small: '1.5rem',
    medium: '3rem',
    large: '5rem',
    xl: '10rem',
  },
  font: {
    sizes: {
      small: '1.5rem',
      medium: '1.8rem',
      large: '3rem',
      superLarge: '5rem',
      xl: '10rem',
    },
  },
};
