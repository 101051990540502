import React, { ReactNode } from 'react';

import { BaseContainer, PageContainerWithSidebar, SideBar } from '~/components';

type Props = {
  children?: ReactNode;
};

export const PageWithSidebar: React.FC<Props> = ({ children }) => {
  return (
    <BaseContainer>
      <SideBar />
      <PageContainerWithSidebar>{children}</PageContainerWithSidebar>
    </BaseContainer>
  );
};
