import styled from 'styled-components';

type SidebarProps = {
  isOpened?: boolean;
};

export const Container = styled.div<SidebarProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 200ms ease-in-out;
  position: sticky;
  top: 0;
  left: 0;

  width: ${(props) => (props.isOpened ? '28rem' : '8rem')};
  height: 100vh;
  background: ${({ theme }) => theme.colors.blue};
  overflow: hidden;
  z-index: 1000;
  box-shadow: 7px 0px 7px #00000029;
`;

export const Session = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Line = styled.div`
  width: 80%;
  height: 2px;
  margin: 3rem 0;
  background: ${({ theme }) => theme.colors.white};
`;

export const ButtonClose = styled.button<SidebarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  align-self: ${(props) => (props.isOpened ? 'flex-end' : 'center')};
  background: transparent;
  margin: 1rem;

  &:hover {
    background: #ffffff25;
  }
`;
