import React from 'react';

import { AuthProvider } from './auth';
import { LoadingProvider } from './loading';
import { SidebarProvider } from './sidebar';
import { AppThemeProvider } from './theme';

export const AppProvider: React.FC = ({ children }) => {
  return (
    <AppThemeProvider>
      <AuthProvider>
        <LoadingProvider>
          <SidebarProvider>{children}</SidebarProvider>
        </LoadingProvider>
      </AuthProvider>
    </AppThemeProvider>
  );
};
