import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  /* max-width: 50rem; */
  min-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  padding: 2rem;

  > h1 {
    font-family: 'Segoe UI';
    font-weight: 500;
    font-size: 50px;
    text-align: center;
  }

  > h2 {
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 16px;
    align-self: flex-start;
  }

  > h3 {
    font-family: 'Segoe UI';
    font-weight: 500;
    font-size: 15px;
    align-self: flex-start;
  }

  @media (max-width: 600px) {
    min-width: 200px;
    h2 {
      font-size: 18px;
      text-align: center;
    }
    h3 {
      font-size: 16px;
      text-align: center;
    }
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
