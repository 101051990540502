import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  > div {
    padding: 2rem;
  }
`;

export const Page = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white_medium};
  border: 1px solid ${({ theme }) => theme.colors.gray_medium};
  padding: 2rem;

  display: flex;
  flex-direction: column;
`;
