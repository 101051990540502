import React, { ReactNode } from 'react';

import { Container, Row } from './styles';

type Props = {
  title: string;
  subtitle?: string;
  icon: string;
  color?: string;
  children?: ReactNode;
};

export const IconCard: React.FC<Props> = ({
  title,
  subtitle,
  color = '#000',
  icon,
  children,
}) => {
  return (
    <Container>
      <Row>
        <div>
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
        </div>
        <div
          style={{
            width: 80,
            height: 80,
            backgroundColor: color,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={icon}
            alt="Ícone do Circuito"
            style={{ width: '60px', height: 'auto' }}
          />
        </div>
      </Row>
      {children}
    </Container>
  );
};
