import styled, { css } from 'styled-components';

type SidebarProps = {
  isOpened?: boolean;
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Nav = styled.button<SidebarProps>`
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: ${(props) => (props.isOpened ? 'flex-start' : 'center')};
  align-items: center;
  padding: 2rem;

  background: transparent;

  &:hover {
    background: #ffffff25;
  }

  > div {
    height: 2.6rem;
    width: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      width: auto;
    }
  }

  > p {
    color: ${({ theme }) => theme.colors.white};
    margin-left: 0.8rem;
    font-size: 14px;
  }
`;

export const SubNavContainer = styled.div<SidebarProps>`
  background: #5d7c8f;
  ${(props) =>
    props.isOpened
      ? css`
          width: 100%;
        `
      : css`
          width: 24rem;
          position: absolute;
          left: 8rem;
        `};
`;

export const SubNav = styled.button<SidebarProps>`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${(props) => (props.isOpened ? '3.5rem' : '1.5rem')};

  background: #ffffff15;

  &:hover {
    background: #ffffff70;
  }

  > div {
    height: 2.6rem;
    width: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      width: auto;
    }
  }

  > p {
    color: ${({ theme }) => theme.colors.white};
    margin-left: 1.2rem;
    font-size: 14px;
  }
`;
