import React, { ReactElement } from 'react';

import { Route } from 'react-router-dom';

import * as UserPages from '~/pages/User';

export function AdminRoutes(): ReactElement {
  return (
    <>
      <Route path="/billing" component={UserPages.Billing} />
      <Route
        path="/energy/consumption"
        component={UserPages.EnergyConsumption}
      />
      <Route path="/circuits" component={UserPages.Circuits} />
    </>
  );
}
